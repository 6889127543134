import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '~components/Link';
import RichText from '~components/RichText';
import PlaceholderImage from '~images/placeholders/resource_card_placeholder.png';
import PlayIcon from '~images/icons/play-icon-white-outline-black-bg.svg';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  title: {
    color: '#000000',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '700',
    margin: 0,
    padding: 0,
    textAlign: 'left',
    [theme.breakpoints.down(1200)]: {
      fontSize: 20,
      textAlign: 'center'
    }
  },
  content: {
    marginTop: 0,
    marginBottom: 18,
    '& > *': {
      marginTop: 8,
      marginBottom: 8,
      color: '#000000',
      fontSize: 14,
      fontFamily: 'Inter',
      fontWeight: '400'
    },
    [theme.breakpoints.down(1200)]: {
      textAlign: 'center'
    }
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'start',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '266px'
    }
  },
  itemTitle: {
    color: '#222222',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '700',
    lineHeight: '114%',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down(1400)]: {
      fontSize: 14
    }
  },
  imageArea: {
    width: '98px',
    position: 'relative',
    display: 'flex',
    flexShrink: 0
  },
  image: {
    maxWidth: '100%',
    borderRadius: 2,
    objectFit: 'contain',
    alignSelf: 'start'
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  ctaLink: {
    color: '#158EFF',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '600'
  }
}));

const TrendingList = ({ title, description, resources, ctaText, ctaPath }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{title}</h3>
      {description?.html && (
        <RichText externalClassName={classes.content} html={description.html} />
      )}

      <div className={classes.listContainer}>
        {resources.map(item => (
          <Link key={item.uid} className={classes.item} to={item.url} underline="none">
            <div className={classes.imageArea}>
              <img
                src={item?.coverImage?.url || PlaceholderImage}
                alt={item?.coverImage?.alt}
                className={classes.image}
              />
              <img src={PlayIcon} alt="play" className={classes.playIcon} />
            </div>

            <h6 className={classes.itemTitle}>{item.title}</h6>
          </Link>
        ))}
        {ctaText && ctaPath && (
          <Link key={ctaText} className={classes.ctaLink} to={ctaPath} underline="none">
            {ctaText}
          </Link>
        )}
      </div>
    </div>
  );
};

TrendingList.propTypes = {
  title: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  ctaPath: PropTypes.string,
  description: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }),
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      category: PropTypes.string,
      uid: PropTypes.string,
      url: PropTypes.string,
      coverImage: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string
      }),
      mediaCount: PropTypes.number,
      firstMediaDuration: PropTypes.string,
      isAudio: PropTypes.bool
    })
  ).isRequired
};

TrendingList.defaultProps = {
  ctaText: '',
  ctaPath: '',
  description: null
};

export default TrendingList;
