import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import TrendingList from '~components/TrendingList';
import Container from '~components/Container';

const useStyles = makeStyles(theme => ({
  trendingListDesktopContainer: {
    position: 'absolute',
    top: '90px',
    right: '4%',
    maxWidth: '310px',
    [theme.breakpoints.down(1450)]: {
      right: '3%',
      maxWidth: '240px'
    },
    [theme.breakpoints.down(1300)]: {
      right: '2%',
      maxWidth: '220px'
    },
    [theme.breakpoints.down(1200)]: {
      display: 'none'
    }
  },
  trendingListMobileContainer: {
    marginTop: 24,
    marginBottom: 12,
    padding: '19px 14px 14px',
    backgroundColor: 'rgba(217, 217, 217, 0.32)',
    borderRadius: '10px',
    maxWidth: '350px'
  }
}));

const ArticleTrendingList = ({ trendingItems, containerSize }) => {
  const theme = useTheme();
  const isBelowMediumViewPort = useMediaQuery(theme.breakpoints.down(1200), { noSsr: true });
  const classes = useStyles();

  if (!isBelowMediumViewPort) {
    return (
      <div className={classes.trendingListDesktopContainer}>
        <TrendingList {...trendingItems} />
      </div>
    );
  }

  return (
    <Container maxWidth={containerSize} center>
      <div className={classes.trendingListMobileContainer}>
        <TrendingList {...trendingItems} />
      </div>
    </Container>
  );
};

ArticleTrendingList.propTypes = {
  trendingItems: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.shape({
      html: PropTypes.string,
      text: PropTypes.string
    }),
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        category: PropTypes.string,
        uid: PropTypes.string,
        url: PropTypes.string,
        coverImage: PropTypes.shape({
          alt: PropTypes.string,
          url: PropTypes.string
        }),
        mediaCount: PropTypes.number,
        firstMediaDuration: PropTypes.string,
        isAudio: PropTypes.bool
      })
    )
  }),
  containerSize: PropTypes.string
};

ArticleTrendingList.defaultProps = {
  trendingItems: null,
  containerSize: 'md'
};

export default ArticleTrendingList;
